/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Galery"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-2x5rq6 --style2 --left --full" name={"[[UNIsectionname1]]"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360,"marginBottom":0}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--102" style={{"maxWidth":""}} content={"<span style=\"color: white;\">Gallery</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60" style={{"paddingTop":50}} layout={"l9"} name={"galery"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={""} src={"https://cdn.swbpg.com/t/38872/fdda54c8d3ba4ee28983882c2338cddf_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":233}} srcSet={"https://cdn.swbpg.com/t/38872/fdda54c8d3ba4ee28983882c2338cddf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/fdda54c8d3ba4ee28983882c2338cddf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/fdda54c8d3ba4ee28983882c2338cddf_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/48c367d368af415c9ed2bebf1d295e73_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":491}} srcSet={"https://cdn.swbpg.com/t/38872/48c367d368af415c9ed2bebf1d295e73_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/48c367d368af415c9ed2bebf1d295e73_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/a7893527772a4c91a0d204005b02152e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":491}} srcSet={"https://cdn.swbpg.com/t/38872/a7893527772a4c91a0d204005b02152e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/a7893527772a4c91a0d204005b02152e_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/31a365bde5aa474391f36796532a2c63_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/38872/31a365bde5aa474391f36796532a2c63_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/31a365bde5aa474391f36796532a2c63_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/31a365bde5aa474391f36796532a2c63_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38872/31a365bde5aa474391f36796532a2c63_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38872/31a365bde5aa474391f36796532a2c63_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":35,"paddingBottom":18}} name={"4nuwe2o0cpp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style5 title-box--center" content={"Tablet"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4 --anim-s5 pb--60" style={{"paddingTop":0}} layout={"l8"} name={"0skn602rmyh"} anim={"4"} animS={"5"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/904b8b69769b4101b15713ea87fb00df_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38872/904b8b69769b4101b15713ea87fb00df_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/904b8b69769b4101b15713ea87fb00df_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/36ef66e2eec44e65931ae728eea1eb47_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38872/36ef66e2eec44e65931ae728eea1eb47_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/36ef66e2eec44e65931ae728eea1eb47_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/a4956652e8ce4c80ae4db9a19cbb2efc_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38872/a4956652e8ce4c80ae4db9a19cbb2efc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/a4956652e8ce4c80ae4db9a19cbb2efc_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":27,"paddingBottom":17}} name={"z3xmd382wcg"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style5 title-box--center" content={"Smartphone"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 pb--60" layout={"l8"} style={{"paddingTop":62}} name={"crjx65xmj9s"} anim={"5"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape3 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/f31cb00b23854717bf487aa14170e338_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38872/f31cb00b23854717bf487aa14170e338_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/f31cb00b23854717bf487aa14170e338_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/f31cb00b23854717bf487aa14170e338_s=860x_.jpg 860w"} style={{"maxWidth":304}} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/6b75e5a815234b85895362b38b14d23d_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38872/6b75e5a815234b85895362b38b14d23d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/6b75e5a815234b85895362b38b14d23d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/6b75e5a815234b85895362b38b14d23d_s=860x_.jpg 860w"} style={{"maxWidth":304}} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/a3a30e1d20014dca898c79c081ddb339_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38872/a3a30e1d20014dca898c79c081ddb339_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/a3a30e1d20014dca898c79c081ddb339_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/a3a30e1d20014dca898c79c081ddb339_s=860x_.jpg 860w"} style={{"maxWidth":304}} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--02" name={"bottom"} style={{"backgroundColor":"rgba(192, 192, 192, 1)","marginTop":38,"paddingTop":38,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1291,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="--center mb--02 mt--02" style={{"paddingTop":0,"paddingBottom":0,"paddingLeft":0,"paddingRight":0}}>
              
              <Title className="title-box title-box--style2 title-box--center fs--43 swpf--uppercase" content={"<a href=\"/contact\">Contact</a>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":334}} content={"www.smartmedicalpad.com<br>info@smartmedicalpad.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(224, 224, 224, 1)","marginTop":0}} layout={"l1"} name={"kh8dvsp8m4"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--style5 title-box--center fs--26" style={{"paddingTop":8,"paddingBottom":6}} content={"Disclaimer"}>
              </Title>

              <Text className="text-box text-box--center fs--18 mt--0" content={"All patient names and data showed in all pictures&nbsp;on this website are completely imaginary and not real. It serves only to demonstrative purposes."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}